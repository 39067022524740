.logoClient {
    position: relative;
    display: flex;
    cursor: wait; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all 500ms;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 1px 4px 3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.logoClient.header{
    padding: .1em;
    /* background-image: linear-gradient(-225deg, var(--bgColor) 0%, #fff 50%, var(--bgColor) 100%); */
    background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
    background-blend-mode: multiply,multiply;
    min-width: 40px;
    height: 70px;
    max-height: 70px;
}

.logoClient.header.right{
    margin-right: .5em;
}

.logoClient.settings{
    padding: .5em;
    width: 100%;
    height: 200px;
    max-height: 200px;
    border: 2px solid lightgray;
    background: rgba(255,255,255,1);
}

.logoClient.loaded {
    cursor: default;
}

.logoClient.header.loaded{
    max-width: 200px;
}

.logoClient.settings.loaded{
    max-width: 100%;
}


.logoClient input {
    display: none;
}

.logoClient .labelLogo{
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    transform: translate(0px, 0px);
    background: none;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
}

.logoClient label img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.logoClient .logoChange {
    opacity: 0;
    display: flex;
    width: max-content;
    border-radius: 10px;
    gap: .2em .5em;
    font-size: 14px;
    padding: .3em;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: rgba(255,255,255,.95);
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all 350ms;
}

.logoClient label:hover ~ .logoChange{
    opacity: 1;
}

@media only screen and (max-width: 768px) { 
    /* .logoClient.header {
        max-height: 60px;
    } */
}

@media only screen and (max-width: 480px) { 
    .logoClient.header.right{
        max-width: 170px;
    }
}