.btnsIndica, .btnsCalculadora {
    display: flex;
    width: 100%;
    gap: .8em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: .5em;
}

.btnsCalculadora {
    justify-content: center;
}

.btnsIndica span, .btnsCalculadora span, .visor span {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: .5em;
    border-radius: 5px;
    font-weight: bold;
    color: black;
    text-shadow: 0px 0px 5px #ffffff, 0px 3px 3px rgb(0 0 0 / 15%);
    min-width: 40px;
    cursor: pointer;
    transition: all 350ms;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btnsIndica span.disabled, .btnsCalculadora span.disabled, .visor span.disabled {
    cursor: not-allowed;
    opacity: .5;
}

.btnsIndica span:not(.disabled):hover {
    transform: scale(.9);
    background: yellow;
}

span.btnBal {
    background: rgb(0, 132, 255);
    color: white;
}

.btnsIndica span:not(.disabled).btnBal:hover {
    background: rgb(1, 99, 190);
}


span.btnDre {
    background: rgb(153, 0, 255);
    color: white;
}

.btnsIndica span:not(.disabled).btnDre:hover {
    background: rgb(104, 0, 173);
}

.btnsCalculadora span, span.operador {
    background: orange;
}

span.parenteses {
    background: none !important;
    box-shadow: none !important;
    color: white !important;
    padding: 0 !important;
    min-width: unset !important;
    font-size: 30px;
    text-shadow: 0px 0px 5px #6e6e6e, 0px 3px 3px rgb(0 0 0 / 15%);
}

.btnsCalculadora span:not(.disabled):hover {
    transform: scale(.9);
    background: darkorange;
}

.calculadora {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 10px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: .5em;
    background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.calculadora .visor {
    display: inline-flex;
    position: relative;
    border-radius: 5px;
    gap: .5em;
    border: 4px solid lightgray;
    width: 100%;
    height: 70px;
    padding: .5em;
    color: white;
    background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
    background-blend-mode: multiply,multiply;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    align-items: center;
    transition: all 350ms;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
}

.calculadora .visor.Cloading {
    overflow: hidden;
    padding: 0;
}

.calculadora .visor.numeros {
    width: 100%;
    height: 50px;
    margin-top: .5em;
    text-shadow: 0px 0px 5px #6e6e6e, 0px 3px 3px rgb(0 0 0 / 15%);
}

.calculadora .visor.numeros h6{
    width: max-content;
    white-space: nowrap;
    margin:0;
    font-size: 20px;
    padding: .5em;
    font-weight: normal;
}

.calculadora .visor.numeros h6 i{
    padding: .3em;
    border-radius:5px;
    margin-left:.2em;
    border: 2px groove lightgray;
    background: rgba(255, 174, 0, 0.4);
}

.calculadora .visor span {
    cursor: default !important;
}


.btnsIndica, .calculadora > *{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.indInfo {
    font-size: 15px;
    font-weight: bold;
}

.textoIndicador {
    max-height: 130px;
    min-height: 130px;
    width: 99%;
    padding-right: 1%;
    overflow-y: auto;
    overflow-x: hidden;
}

.formulaIndicador {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: .2em;
    overflow-y: auto;
    overflow-x: hidden;
    padding: .5em;
}

.formulaIndicador .dividir {
    display: block;
    height: 2px;
    width: 85%;
    background: rgba(0,0,0,.5);
}


.textoIndicador {
    scrollbar-width: auto;
    scrollbar-color: #4facfe rgba(255, 255, 255, 0.5);
}

.formulaIndicador::-webkit-scrollbar-track, .textoIndicador::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
}

.formulaIndicador::-webkit-scrollbar-track-piece:end, .textoIndicador::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: .2em; 
}

.formulaIndicador::-webkit-scrollbar-track-piece:start, .textoIndicador::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: .2em;
}

@media only screen and (max-width: 480px) { 
  
    .indInfo {
        display: none;
    }
    
}

@media only screen and (min-width: 768px) { 
  
    .calculadora {
        position: sticky;
        top:0px;
    }
    
}