.alertArea {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 200ms;
    background: none;
}

.alertArea.normal {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.alertArea.toasty {
    flex-direction: row;
    flex-wrap: wrap;
}

.alertArea.hide {
    opacity: 0;
    visibility: hidden;
}

.alertArea.show {
    opacity: 1;
    visibility: visible;
}

.alertArea.normal.show {
    background: rgba(255,255,255,.8);
}

.alertArea .alerta {
    transition: all 350ms, width 0ms;
    background: rgb(229,229,229);
    background: linear-gradient(to bottom,  rgba(229,229,229,1) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 );
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.alertArea.normal .alerta {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 280px;
    max-width: 600px;
    padding: 0 0 1em 0;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    border-radius: 8px;
    overflow: hidden;
}

.alertArea.normal .alertHtmlItem {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    padding: .5em;
}

.alertArea.toasty .alerta {
    display: grid;
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    height: fit-content;
    min-height: 60px;
    grid-template-columns: 70px 250px;
    gap: 0;
    pointer-events: stroke;
    margin: 4em 2em;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.alertArea.hide .alerta {
    opacity: 0;
    visibility: hidden;
    transform: scale(.2);
}

.alertArea.show .alerta {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.alertArea .alerta .alertTitle {
    display: inline-flex;
    font-size: 18px;
    padding: .5em;
    margin: 0;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(0 0 0 / 40%);
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.alertArea .alerta .toastAlertIcon {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.alertArea .alerta .toastAlertIcon .toastAlertIconInfo {
    font-size: 40px;
    color: white;
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,.3));
}

.alertArea .alerta .alertTitle.primary, .alertArea .alerta .toastAlertIcon.primary {
    background-image: linear-gradient(to left, #89f7fe 0%, #66a6ff 100%) !important;
}

.alertArea .alerta .alertTitle.warning, .alertArea .alerta .toastAlertIcon.warning {
    background-image: linear-gradient(to left, #ecff45 0%, #fac721 100%) !important;
}

.alertArea .alerta .alertTitle.success, .alertArea .alerta .toastAlertIcon.success {
    background-image: linear-gradient(to left, #f9f047 0%, #0fd850 100%) !important;
}

.alertArea .alerta .alertTitle.error, .alertArea .alerta .toastAlertIcon.error {
    background-image: linear-gradient(to left, #f94747 0%, #d80f0f 100%) !important;
}

.alertArea .closeAlert{
    display: block;
    height: 20px;
    padding: .1em;
    cursor: pointer;
    color: white;
    transition: all 350ms;
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,.3));
}

.alertArea.toasty .closeAlert {
    position: absolute;
    right: -3px;
    top: -3px;
    color: black;
    background: rgb(229,229,229);
    background: linear-gradient(to bottom,  rgba(229,229,229,1) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 );
    border-radius: 100%;
}

.alertArea .closeAlert:hover{
    transform: scale(1.2);
}

.alertArea.normal .alerta .alertMsg, .alertArea.toasty .alerta .alertMsg{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: bold;
    padding: 1em;
    line-height: 1.2em;
    margin: 0;
    font-size: 15px;
    text-shadow: 0px 0px 5px #ffffff, 0px 3px 3px rgb(0 0 0 / 15%);
}

.alertArea.normal .alerta .alertMsg p{
    padding: 0;
    margin: .3em 0 0 0;
}

.formAlert {
    position: relative;
    display: flex;
    width: 90%;
    padding: 0;
    margin-top: 0;
    justify-content: center;
    align-items: center;
}

.alertArea.toasty.center {
    justify-content: center;
    align-items: center;
}

.alertArea.toasty.left-center {
    align-items: center;
    justify-content: flex-start;
}

.alertArea.toasty.right-center {
    align-items: center;
    justify-content: flex-end;
}

.alertArea.toasty.top-center {
    justify-content: center;
    align-items: flex-start;
}

.alertArea.toasty.bottom-center {
    justify-content: center;
    align-items: flex-end;
}

.alertArea.toasty.top-left {
    justify-content: flex-start;
    align-items: flex-start;
}

.alertArea.toasty.top-right {
    justify-content: flex-end;
    align-items: flex-start;
}

.alertArea.toasty.bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
}

.alertArea.toasty.bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
    .formAlert {
        gap: 1.5em;
    }
}

@media only screen and (max-width: 768px) {
    .alertArea.toasty .alerta {
        margin: 2em auto !important;
    }
}

@media only screen and (max-height: 380px) {
    .alertArea .alerta .alertMsg {
        font-size: 12px;
    }

    .alertArea.normal .alerta {
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
    }
    
}