.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: .9em;
    width: max-content;
}

.checkbox p{
    padding: 0;
    margin: .1em 0 0 0;
}

.checkbox input {
    display: inline-block;
    padding: .5em;
    border-radius: 10px;
    margin: 0 .2em 0 0;
    outline: 0;
    border: 0;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}