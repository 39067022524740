.Loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    width: 100vw;
    height: 100vh;
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 350ms;
    color: white;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.2);
    transform-style: preserve-3d;
}

.Loader.firstLoad {
    background-color: none;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}

.Loader.firstLoad::after{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:-1;
    opacity: 0;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    animation: animaAfter 2s ease-in-out infinite 2s alternate-reverse;
}

.Loader.firstLoad::before{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:-2;
    opacity: 0;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    animation: animaBefore 2s ease-in-out infinite alternate-reverse;
}

.Loader.show {
    opacity: 1;
    visibility: visible;
    cursor: wait;
}

.Loader.hide {
    opacity: 0;
    visibility: hidden;
}

@keyframes animaAfter {
    0%{
        opacity: 0;
        transform: scale(2) rotate(0);
    }
    100%{
        opacity: 1;
        transform: scale(1) rotate(180);
    }
}

@keyframes animaBefore {
    0%{
        opacity: 0;
        transform: scale(1) rotate(180);
    }
    100%{
        opacity: 1;
        transform: scale(2) rotate(0);
    }
}

.Loader .loaderLogo {
    max-width: 70%;
    border: 0; 
    overflow: visible;
    transition: all 500ms;
}

.Loader img {
    max-height: 50%;
    animation: pathsAnimation 2s ease-in-out infinite alternate;
}

.Loader:not(.firstLoad) .loaderLogo {
    max-width: 30%;
}

.Loader:not(.firstLoad) img {
    display: none;
}

.Loader .loaderLogo g{
    transform: translate(-12%, 0);
}

.Loader:not(.firstLoad) .loaderLogo path{
    display: none;
}

.Loader.firstLoad .loaderLogo path{
    transform-origin: 50% 50%;
    transform: translateY(0px);
    animation: pathsAnimation 2s ease-in-out infinite alternate;
}

@keyframes pathsAnimation {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(15px);
    }
}

.Loader .loaderLogo circle{
    transform-origin: 50% 50%;
    transform: scale(1);
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,.4));
    animation: circlesAnimation 1s ease-in-out infinite alternate;
}

.Loader .loaderLogo .circle1{
    animation-delay: 0;
}

.Loader .loaderLogo .circle2{
    animation-delay: 500ms;
}

.Loader .loaderLogo .circle3{
    animation-delay: 1s;
}

.Loader .loaderLogo .circle4{
    animation-delay: 1.5s;
}

@keyframes circlesAnimation {
    from {
        transform: scale(1);
        filter: drop-shadow(0px 0px 5px rgba(0,0,0,.4));
    }

    to {
        transform: scale(1.2);
        filter: drop-shadow(-10px -10px 10px rgba(0,0,0,.2));
    }
}

@media only screen and (max-width: 480px) { 
    .Loader:not(.firstLoad) .loaderLogo {
        max-width: 30%;
    }
}