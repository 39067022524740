.loginArea {
    position: relative;
    color: white;
    width: 100%;
    max-height: fit-content;
    padding: 0;
    margin: 0;
    font-size: 14px;
    grid-area: login;
    align-items: center;
    justify-items: center;
    transform-style: preserve-3d;
    opacity: 1 !important;
    visibility: visible !important;
}

.logado {
    display: flex;
    position: relative;
    gap: 1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    align-self: flex-end;
}


.logado .txtsClient{
    display: flex;
    flex-direction: column;
}

.logado .txtsClient .nomeBts {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: .5em;
}

.logado .txtsClient p, .loginArea .promoTxts {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    margin: 0;
    padding: 0;
    transition: all 500ms;
}

.loginArea .promoTxts{
    text-align: end;
}

.logado .txtsClient .btsLogado {
    display: flex;
    position: relative;
    /* width: 100%; */
    gap: .2em;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.logado .txtsClient .btsLogado.admin {
    justify-content: space-around;
}

.logado .txtsClient .btsLogado > * {
    display: flex;
    padding: .5em;
    border-radius: 50%;
    cursor: pointer;
    color: var(--textColor);
}

.logado .txtsClient .btsLogado > *:not(.active):hover, .logado .txtsClient .btsLogado > *.active{
    color: rgb(10, 127, 194);
    background: white;
}

.closeLoginAreaBt{
    display: none;
}

@media only screen and (max-width: 960px) { 
    .logado, .loginArea {
        margin-bottom: 1em;
    }
}


@media only screen and (max-width: 768px) { 
    
    .logado .txtsClient p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 700px) { 
    .loginArea {
        z-index: 10;
        position: absolute;
        top:-1em;
        width: 100vw;
        padding: 1em;
        background-color: rgb(49, 49, 49);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding-bottom: 2em;
        transition: all 350ms;
    }

    .loginArea {
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .loginArea > * {
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .loginAreaOpen, .loginArea.promoToken {
        opacity: 1 !important;
        visibility: visible !important;
    }

    .loginAreaOpen > *, .loginArea.promoToken > *{
        opacity: 1 !important;
        visibility: visible !important;
    }

    .closeLoginAreaBt {
        display: block;
        height: 20px;
        padding: .2em;
        color: rgb(10, 127, 194);
        transition: all 350ms;
    }

    .closeLoginAreaBt:hover{
        transform: scale(1.2);
    }

    .loginArea .promoTxts{
        text-align: center;
    }
}