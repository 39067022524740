html, body{
	position: relative;
	padding: 0px;
  margin: 0px;
	overflow: hidden;
	-webkit-text-size-adjust: 100%;
	background: none;
	touch-action: none !important;
  -ms-touch-action: none !important;
  -webkit-touch-action: none !important;
  width: 100% !important;
  height: 100vh !important;
  min-height: 100vh !important;
  min-height: -webkit-fill-available !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  height: 100vh !important;
  height: -webkit-fill-available !important;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}


abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  max-width: 100%;
}


button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}


textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}


[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}


[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}


template {
  display: none;
}

[hidden] {
  display: none;
}

.pointer{
  cursor: pointer;
}

.pageTitle {
  display: inline-flex;
  position: relative;
  padding: .2em;
  color: rgb(184, 184, 184);
  font-size: 30px;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.pageTitle.sub {
  font-size: 25px;
  margin-left: 0;
}

.pageTitle.sub-m {
  font-size: 20px;
  margin-left: 0;
}

.pageTitle::before{
  position: absolute;
  content: '';
  width: .2em;
  left: -.2em;
  background: rgb(10, 127, 194);
  height: 100%;
}

.pageInfos{
  display: inline;
}

.container {
  height: fit-content;
  margin: 1em auto 2em auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.Cloading{
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.Cloading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
) lightgray;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

svg > g > g.google-visualization-tooltip { pointer-events: none }

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@media only screen and (max-width: 480px) { 
  
  .pageTitle {
      font-size: 20px;
  }

  .pageTitle.sub {
    font-size: 18px;
  }

  .pageTitle.sub-m {
    font-size: 14px;
  }
  
}

@media  only screen and (max-width: 300px), only screen and (max-height: 300px) {
  label {
    font-size: 13px;
    margin-top: .3em;
    margin-left: .4em;
  }
}

.bg-cards-title {
  background-color: #2072bb !important;
}