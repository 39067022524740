.logos {
    display: flex;
    padding: 1.5em;
    width: 100%;
    gap: .5em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-area: logos;
    overflow: hidden;
}

.logos > * {
    max-height: 50px;
    object-fit: contain;
    transition: all 350ms;
}

.logos img.audit {
    filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
}

@media only screen and (max-width: 992px) { 
    .logos {
        margin-top: .5em;
        margin-bottom: .5em;
        max-height: 100px;
        transition: all 500ms;
    }

    /* .logos.hide {
        max-height: 0px;
        padding: 0px;
    } */

    .logos.hide {
        margin-top: -200px;
    }

    .logos > * {
        max-width: 50%;
    }    
}