.switch_container {
    position: relative;    
    display: flex;
    flex-wrap: wrap;
    margin-top: .6em;
    height: fit-content;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: .5em;
    flex-direction: row;
    transform-style: preserve-3d;
}

.switch_container h6{
    width: fit-content;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-shadow: 0 0 3px rgba(0 0 0 / 40%);
}

.switch_container .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    border-radius: 28px;
    overflow: hidden;
    border: 2px groove rgba(0, 0, 0, 0.5);
}

.switch_container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch_container .switch .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--offColor);
    transition: 0.4s;
    border-radius: 28px;
}

.switch_container .switch .slider .ballSlider{
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2px;
    background-color: var(--ballColor);
    border-radius: 28px;
    transition: 0.4s;
}

.switch_container .switch input:checked + .slider{
    background-color: var(--onColor);
}

.switch_container .switch input:checked + .slider .ballSlider{
    transform: translateX(24px);
}