.Layout {
    display: grid;
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100vw;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: 100%;
    gap: 0px 0px;
    grid-template-areas: "header"
        "conteudo"
        "footer";
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: rgb(235,235,235);
    /* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
}

.conteudo {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    grid-area: conteudo;
    padding: 1em;
    margin: 0;
    width: calc(100% - .5em);
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.conteudo::after, .conteudo::before{
    content: '';
    display: block;
    padding: .5em;
}

.logoBotton {
    display: flex;
    margin-top: 4em;
    height: 7vh;
    pointer-events: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    opacity: .5;
    align-items: center;
}

.logoBotton > * {
    max-height: 100%;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #4facfe rgba(255,255,255,0);
}


*::-webkit-scrollbar {
    width: 8px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: rgba(255,255,255,0);
}

*::-webkit-scrollbar-thumb {
    background-color: #4facfe;
    border-radius: 8px;
    border:0;
}

*::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 3em; 
}

*::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 3em;
}


@media only screen and (max-width: 768px) { 
    .conteudo {
        width: 100%;
    }   
}

@media only screen and (min-width: 1440px) { 
    .logoBotton {
        height: 5vh;
    }
}

