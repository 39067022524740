.PageNotFound {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    display: flex;
    padding: 0 !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
    color: white;
}

.PageNotFound .notFoundBack{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0 !important;
    margin: 0 !important;
    mask-image: radial-gradient(rgba(0, 0, 0, 1) 0%, transparent 70%);
    background-repeat: repeat;
    background-size: 100% 200%;
    background-position: 0% 0%;
    transition: all 5ms;
    animation:  5s linear infinite backNotFound;
    opacity: .5;
}

@keyframes backNotFound {
    0%{background-position: 0% 0%;}
    100%{background-position: 0% 200%;}
}

.PageNotFound .space {
    position: absolute;
    height: 200%;
    z-index: 1;
    mask-image: radial-gradient(rgba(0, 0, 0, 1) 0%, transparent 60%);
    transition: all 5ms;
    opacity: .5;
}

.PageNotFound h2 {
    margin: 0;
    font-size: 10em;
    transition: all 5ms;
    text-shadow: 0 0 15px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5);
    z-index: 2;
}

.PageNotFound h4{
    margin:0;
    padding: .5em;
    font-size: 2em;
    text-shadow: 0 0 15px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5);
    transition: all 5ms;
    z-index: 2;
}

@media only screen and (max-height: 400px) { 
    .PageNotFound {
        flex-direction: row;
        gap: 0 1em;
        font-size: 10px;
    }
}

@media only screen and (max-width: 480px) { 
    .PageNotFound {
        font-size: 10px;
    }
}

