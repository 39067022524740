.inputContainer {
    display: flex;
    position: relative;
    margin-top: .5em;
    border-radius: 0px;
    overflow: unset;
    border-radius: 5px;
}

.inputContainer.disabled {
    opacity: .5;
    pointer-events: none;
}

.inputContainer.Cloading {
    overflow: hidden;
}

.inputContainer input, .inputContainer textarea {
    display: inline-block;
    width: 100%;
    padding: .5em;
    border-radius: 5px;
    text-indent: 0px;
    overflow: hidden;
    outline: 0;
    font-size: 1em;
    border: 0;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.inputContainer textarea {
    overflow: auto;
    resize: none;
}

textarea {
    scrollbar-width: auto;
    scrollbar-color: #4facfe rgba(100, 100, 100, 0.5);
}

textarea::-webkit-scrollbar-track {
    background: rgba(100, 100, 100, 0.5);
}

textarea::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 0; 
}

textarea::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 0;
}

.inputContainer label {
    position: absolute;
    max-height: 90%;
    max-width: 85%;
    overflow: hidden;
    color: black;
    padding: 0 .3em .2em .3em;
    border-radius:5px;
    opacity: .5;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: none;
    transform-origin: 0 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: translate(.1em, .2em) scale(1);
    font-size: 16px;
    transition: all 200ms;
}

.inputContainer input:focus + label, .inputContainer input:not(input[value='']) ~ label,
.inputContainer input.labelLeft:focus + label,
.inputContainer input.labelLeft:-webkit-autofill + label,

.inputContainer textarea:focus + label, .inputContainer textarea:not(:placeholder-shown) ~ label,
.inputContainer textarea.labelLeft:focus + label,
.inputContainer textarea.labelLeft:-webkit-autofill + label
{
    background-image: linear-gradient(to top, rgba(49, 49, 49, 0) 0%, rgba(49, 49, 49, 1) 20%);
    border: 2px solid lightgray;
    border-bottom: 0px solid transparent;
    color: white;
    opacity: 1;
    max-width: 125%;
    transform: translate(10px, -16px) scale(.8);
}

.inputContainer .inputLabel {
    position: absolute;
    display: flex;
    color: black;
    padding: .5em 1em;
    width: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.inputContainer .inputLabel.textArea {
    align-items: flex-start;
    height: 100%;
}

.inputContainer .inputLabel.Right{
    right:0;
    border-left: 2px groove white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.inputContainer .inputLabel.Left{
    left:0;
    border-right: 2px groove white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.inputContainer input.labelLeft, .inputContainer textarea.labelLeft {
    padding-left: 2.5em;
}

.inputContainer input.labelRight,  .inputContainer textarea.labelRight{
    padding-right: 2.5em;
}

.inputContainer input.labelLeft.labelRight, .inputContainer textarea.labelLeft.labelRight {
    padding-left: 2.5em;
    padding-right: 2.5em;
}

.inputContainer input.labelLeft ~ label, .inputContainer textarea.labelLeft ~ label {
    transform: translate(2em, .2em) scale(1);
}