.slidesHomeContainer {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.slidesHomeContainer .slidesHome {
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.slidesHomeContainer .slidesHome .slidesHomeImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}