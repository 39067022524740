.navMenu {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    grid-area: menuarea;
    transform: translate(25%, 1em)
}

.navMenu.logged {
    transform: translate(0%, 1em) !important;
}

.navMenu * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navMenu ul {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0 auto 1em auto;
    padding: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.navMenu ul > * {
    text-decoration: none;
}

.navMenu ul > *:link {
    color: inherit;
}
 
.navMenu ul > *:visited {
    color: inherit;
}
  
 
.navMenu ul > *:hover {
    color: inherit;
}
  

.navMenu ul > *:active {
    color: inherit;
}

.navMenu a {
    transition: all 350ms;
}

.navMenu li {
    position: relative;
    display: flex;
    transform-style: preserve-3d;
    border-radius: 45px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 0px solid transparent;
    border-bottom: none;
    z-index: 1;
    transition: all 350ms;
}

.navMenu a:not(:first-child), .navMenu li:not(:first-child){
    margin-left: .5em;
}

.navMenu:not(.logged) a, .navMenu:not(.logged) li{
    opacity: 0;
    visibility: hidden;
    width: 0px;
    height: 0px;
    pointer-events: none;
}

.navMenu a:first-child, .navMenu li:first-child, .navMenu.logged a, .navMenu.logged li{
    opacity: 1;
    visibility: visible;
    width: 45px;
    height: 45px;
    pointer-events: unset;
}

.navMenu li:not(.openLogin):not(.logoutBt)::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100px);
    background-image: linear-gradient(to top, #0fd850 10%, #f9f047 90%);
    box-shadow: rgb(0 0 0 / 0%) 0px 2px 4px, rgb(0 0 0 / 0%) 0px 7px 13px -3px, rgb(0 0 0 / 0%) 0px -3px 0px inset, rgb(0 255 0 / 0%) 0 0 16px 4px;
    transition: all 400ms;
}

.navMenu a.active li:not(.openLogin):not(.logoutBt)::before{
    transform: translate(0px, -35px);
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset, rgb(0 255 0 / 75%) 0 0 16px 4px;
    opacity: 1;
    visibility: visible;
}

.navMenu a.active li {
    transform: translate(0px, 25px);
    background-color: rgb(10, 127, 194);
    border: 4px solid rgb(235, 235, 235);
    border-radius: 10px;
    transition: all 350ms;
}

.navMenu li.logoutBt {
    cursor: pointer;
}

.navMenu a:not(.active) li:hover, .navMenu li.logoutBt:hover,  .navMenu li.openLogin:hover{
    background-color: white;
    z-index: 2;
}

.navMenu li p {
    position: absolute;
    font-size: 11px;
    border: none;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 350ms;
    font-weight: 900;
    padding: .3em;
    color: var(--textColor);
    pointer-events: none;
    transform: translate(0px, 0px);
}

.navMenu a:not(.active) li:hover p, .navMenu li.logoutBt:hover p, .navMenu li.openLogin:hover p{
    opacity: 1;
    visibility: visible;
    transform: translate(0px, -25px);
}

.navMenu li svg {
    position: absolute;
    margin-top:0;
    color: var(--textColor);
    height: 1.2em !important;
    transition: all 350ms;
    z-index: -1;
}

.navMenu li:hover svg, .navMenu a.active li svg {
    transform: scale(1.2);
}

.navMenu a:not(.active) li:hover svg,  .navMenu li.logoutBt:hover svg, .navMenu li.openLogin:hover svg {
    color: rgb(10, 127, 194);
}

.navMenu li.openLogin{
    display: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) { 
    .navMenu li.openLogin {
        display: flex;
        opacity: 1;
        visibility: visible;
        width: 45px;
        height: 45px;
        pointer-events: unset;
    }
}