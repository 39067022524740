.btn {
    display: inline-flex;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(0 0 0 / 40%);
    overflow: hidden;
    outline: 0;
    font-size: 1em;
    border: 0;
    gap: 0 .5em;
    width: 100%;
    transition: all 350ms;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btn:hover {
    color: black;
    text-shadow: 0 0 3px rgba(0 0 0 / 0%);
  }
  
  .btn.primary {
    background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
  }
  
  .btn.warning {
    background-image: linear-gradient(120deg, #ecff45 0%, #fac721 100%) !important;
  }
  
  .btn.success {
    background-image: linear-gradient(120deg, #f9f047 0%, #0fd850 100%) !important;
  }
  
  .btn.danger {
    background-image: linear-gradient(120deg, #f94747 0%, #d80f0f 100%) !important;
  }