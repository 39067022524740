.selOptsArea {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.9);
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 200ms;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 350ms;
}

.selOptsArea.show {
    opacity: 1;
    visibility: visible;
    pointer-events: unset;
}

.selOpts {
    display: grid;
    width: 90%;
    height: 90%;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1.7fr;
    gap: 0px 0px;
    grid-template-areas: "selBusca"
        "selList";
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.selOptsSearch {
    display: flex;
    width: 100%;
    grid-area: selBusca;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    flex-wrap: nowrap;
}

.selOptsSearch > .inputContainer{
    width: 100%;
}

.selOptsSearch > button {
    position: absolute;
    margin-top: .5em;
    width: 100px;
}

.selOptsList {
    width: 100%;
    height: 100%;
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-area: selList;
}

.selOptsList li{
    margin: 1em;
    padding: .5em;
    background: lightgray;
    cursor: pointer;
    box-shadow: rgba(10, 127, 194, 0.3) 0px 0px 0px 3px;
    transition: all 350ms;
}

.selOptsList li.selSelected {
    background-color:  rgb(10, 194, 35);
    box-shadow: rgba(84, 194, 10, 0.3) 0px 0px 0px 3px;
    color: white;
    font-weight: bold;
}

.selOptsList li.selSelected, .selOptsList li:hover, li.disabled {
    transform: scale(.985);
}

.selOptsList li.disabled {
    cursor: default !important;
    background-color:  rgb(128, 8, 8);
    box-shadow: rgba(194, 10, 10, 0.3) 0px 0px 0px 3px;
    color: rgba(255,255,255,.5);
    opacity: .5;
}

.selOptsArea .closeSelectBt {
    position: absolute;
    top: 3vh;
    left:calc(50% - .8em);
    display: block;
    cursor: pointer;
    height: 20px;
    padding: .2em;
    color: rgb(10, 127, 194);
    transition: all 350ms;
}

.selectedTags {
    position: relative;
    width: 100%;
    margin: 1em 0;
    padding: .2em;
    display: flex;
    line-height: 1em;
    gap: 1em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: black;
}

.selectedTags span {
    position: relative;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    border-radius: 5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-image: linear-gradient(180deg, #89f7fe 0%, #66a6ff 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    padding: .4em 2em .4em .4em;
    flex-direction: row;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectedTags span .selectedTagsRem{
    position: absolute;
    right:.2em;
    font-size: 18px;
    cursor: pointer;
}

#selectContainner {
    width: 100vw;
    height: 100vh;
}