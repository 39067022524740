.Cookies {
    position: fixed;
    display: flex;
    z-index: 10;
    padding: 1em;
    margin: 0;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgb(10, 127, 194);
    color: white;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    opacity: 0;
    transition: all 350ms;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.Cookies.show{
    opacity: 1;
}

.Cookies .btn_cookies {
    margin: 0 auto;
    pointer-events: none;
}

.Cookies.show .btn_cookies{
    pointer-events: all;
}