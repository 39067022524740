header {
    position: relative;
    width: 100%;
    display: grid;
    grid-area: header;
    grid-auto-columns: 100%;
    grid-template-columns: 30% 48%;
    grid-template-areas: "logos login"
        "menuarea menuarea";
    grid-template-rows: 80px 0px;
    gap: 0;
    padding: .5em 2em;
    z-index: 3;
    transition: all 350ms;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: center;
}

header > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

header.logged {
    grid-template-columns: 30% 30%;
}

@media only screen and (min-width: 1440px) { 
    header {
        grid-template-columns: 30% 60%;
    }
}

@media only screen and (max-width: 960px) { 
    header, header.logged {
        grid-template-columns: 100%;
        grid-template-rows: min-content;
        grid-template-areas: "logos"
        "login"
        "menuarea";
    }
}