footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    max-height: 100px;
    align-items: center;
    flex-wrap: wrap;
    padding: .2em;
    z-index: 2;
    transition: all 400ms;
    grid-area: footer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    background-color: rgb(79, 172, 254);
}

footer > * {
    margin: .5em;
    color: var(--textColor);
    text-decoration: none;
    text-shadow: 0 0 5px rgb(21 101 192);
    font-size: .9rem;
}

@media only screen and (max-width: 768px) { 
    footer > * {
        font-size: .8rem;
    }
}


@media only screen and (max-width: 992px) { 
    footer {
        margin-bottom: -65px;
    }
    footer.show {
        margin-bottom: 0;
    }
}

