.Home {
  position: relative;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  color: black;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100% !important;
  padding: 0 !important;
}

.Home::after, .Home::before{
  content: '';
  display: block;
  padding: 0 !important;
}