.logoIdr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logoIdr.hoverSlides{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 1em;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.logoIdr.hoverSlides.uploadSlides{
    z-index: 0;
    width: 35%;
    padding: .2em;
    border-radius: 5px;
}

.logoIdr.hoverSlides img {
    border-radius: 10px;
}

.logoIdr.hoverSlides.uploadSlides img{
    border-radius: 5px;
}

.logoIdr .logoChange {
    opacity: 0;
    display: flex;
    width: max-content;
    border-radius: 10px;
    gap: .2em .5em;
    font-size: 14px;
    padding: .3em;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: rgba(255,255,255,.95);
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all 350ms;
}

.logoIdr label:hover ~ .logoChange{
    opacity: 1;
}

.logoIdr input {
    display: none;
}

.logoIdr img.auditIDR {
    max-height: 55px;
    padding: .2em;
    /* background-color: white; */
    border-radius: 5px;
}

@media only screen and (min-width: 1600px) {

    .logoIdr.hoverSlides:not(.uploadSlides){
       padding: 1.5em;
    }
}

@media only screen and (max-width: 1400px) {

    .logoIdr.hoverSlides:not(.uploadSlides){
        width: 25%;
    }
}

@media only screen and (max-width: 768px) {

    .logoIdr.hoverSlides:not(.uploadSlides){
        width: 50%;
    }
}

@media only screen and (max-width: 400px) {

    .logoIdr.hoverSlides{
        padding: .5em;
    }
}