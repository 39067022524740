.cardCircle {
    position: relative;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 0.5s;
    margin: auto;
    flex-direction: column;
}

.cardCircle .percentCicle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 350ms;
    background: none;
}

.cardCircle .percentCicle .numberCircle {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardCircle .percentCicle .numberCircle h2 {
    color: var(--textColor);
    font-weight: 700;
    transition: all 350ms;
}

.cardCircle .percentCicle .numberCircle h2 span{
    font-size: 20px;
    color: var(--textColor);
}

.cardCircle .percentCicle .circleSvg {
    position: absolute;
    width: 130px;
    height: 130px;
}

.cardCircle .percentCicle .circleSvg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--strokeColor);
    stroke-width: 8;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

.cardCircle .percentCicle .circleSvg circle:nth-child(2) {
    stroke-dasharray: 380;
    stroke-dashoffset: calc(380 - (380 * var(--percent)) / 100);
    stroke: var(--color);
}