.slideEditImg{
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    border: 2px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.trashSlides{
    width: 95%;
    min-height: 200px;
    border: 2px solid lightgray;
    border-radius: 5px;
}