.indicadoresContainer {
    width: 95%;
    margin: auto;
    --grid-layout-gap: 15px;
    --grid-column-count: 2;
    --grid-item--min-width: 300px;

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * calc(var(--grid-layout-gap)*6));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
  
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    gap: var(--grid-layout-gap) calc(var(--grid-layout-gap)*6);
}

.indicadoresContainer.topo {
    background: rgb(235,235,235);
    z-index: 2;
    position: sticky;
    top: -20px;
    margin-bottom: .4em;
    padding-top: 30px;
}

.indicadoresContainer .indicadorItem {
    height: auto;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}

.indicadoresContainer .indicadorNome {
    display: flex;
    padding: .5em;
    font-size: 1rem;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-shadow: 0px 0px 5px #ffffff, 0px 3px 3px rgb(0 0 0 / 15%);
}

.indicadoresContainer .indicadoresTopo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    padding: .5em 0;
    border-bottom: 4px white groove;
    border-top: 4px white groove;
}

.indicadoresContainer .indicadoresTopo span, .indicadoresContainer .indicadoresTopo b {
    display: flex;
    position: relative;
    padding: .3em;
    flex-basis: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    color: white;
    font-weight: 900;
    transition: all 350ms;
    text-shadow: 0px 0px 5px #858585, 0px 3px 3px rgb(0 0 0 / 15%);
}

.indicadoresContainer .indicadoresTopo span{
    height: 85px;
    width: 85px;
    flex-basis: auto;
    background: white var(--bgimg);
    background-size: 90%;
    background-position: center;
    background-repeat:no-repeat;
    background-origin: content-box;
    /* -webkit-filter: grayscale(100%);
    filter: grayscale(100%); */
    margin-bottom:1.5em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; 
    border-radius: 100%;
    color: black;
}

/* .indicadoresContainer .indicadoresTopo span:first-child{
    margin-left: -4%;
}

.indicadoresContainer .indicadoresTopo span:nth-child(2){
    margin-left: 4%;
} */

/* .indicadoresContainer .indicadoresTopo span:hover{
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
} */

.indicadoresContainer .indicadoresTopo b{
    font-size: 1.5rem;
    color: black;
    text-shadow: 0px 0px 5px #ffffff, 0px 3px 3px rgb(0 0 0 / 15%);
}

.indicadoresContainer .indicadoresTopo b:not(:first-child){
    border-left: 2px groove white;
}

.indicadoresContainer .indicadoresTopo span i {
    position: absolute;
    bottom: -2em;
    margin-top: 10px;
    font-size: smaller;
    width: max-content !important;
}

.locadoraSelect {
    position: absolute;
    top:0;
    left:45%;
    display: flex;
    color: black;
    padding: .5em 1em;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: -1;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.locadoraSelectArrow{
    position: absolute;
    top:10px;
    right: -25px;
}

@media only screen and (max-width: 1024px) { 
    
    .indicadoresContainer .indicadoresTopo span {
        font-size: .8rem;
    }

    .indicadoresContainer .indicadoresTopo b{
        font-size: 1.2rem;
    }    

}

@media only screen and (max-width: 991px) { 
    
    .indicadoresContainer .indicadoresTopo:nth-child(1){
        display: none;
    }

}

@media only screen and (max-width: 480px) { 
    .indicadoresContainer {
        width: auto;
        --grid-item--min-width: 280px;
    }

    .indicadoresContainer .indicadorNome {
       
        font-size: .9rem;
        
    }

    .indicadoresContainer .indicadoresTopo span:first-child{
        margin-left: -4%;
    }
    
    .indicadoresContainer .indicadoresTopo span:nth-child(2){
        margin-left: 1%;
    }

    .locadoraSelect {
        width: 68%;
    }

    .locadoraSelectArrow{
        right: -8px;
    }
}

@media only screen and (max-width: 300px) { 
    .indicadoresContainer {
        --grid-item--min-width: 180px;
    }

    .indicadoresContainer .indicadoresTopo span {
        font-size: .6rem;
    }

    .indicadoresContainer .indicadoresTopo b{
        font-size: 1rem;
    }
}