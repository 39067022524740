.userCards{

    position: relative;
    border: none;
    padding: 1em;
    margin-bottom: 1em;
    transition: all 350ms;
    
}

.userCards:hover{
    transform: scale(1.1);
}

.userCards .userCardsIn {
    position: relative;
    display: flex;
    height: 260px;
    width: 100%;
    border: 4px solid var(--borderColor);
    padding: .5em;
    background-image: linear-gradient(to top, var(--borderColor) -10%, #eef1f5 25%);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    transform-style: preserve-3d;
    border-radius: 10px;
}

.userCards .userCardsIn .usersLogo {
    position: absolute;
    width: 70px;
    height: 70px;
    background-color: var(--borderColor);
    background-image: var(--img);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.userCards .userCardsIn .usersPlano{
    position: absolute;
    top: -18px;
    right: 6px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.userCards .userCardsIn .usersAssAtivo{
    position: absolute;
    top: -18px;
    left: 6px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    
}

.userCards .userCardsIn .usersBts {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: calc(100% - 70px);
    margin: 0;
}

.userCards .userCardsIn .docEmpresa {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: flex-start;
    gap: .2em;
    margin-top: -.8em;
    cursor: pointer;
}

.userCards .userCardsIn .docEmpresa .usersDocInput{
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

.topDash {
    width: 100%;
}

.fontsCards {
    font-size: 1rem;
}


@media only screen and (max-width: 768px) {

    .userCards .userCardsIn {
        height: 240px;
    }
    
    .userCards .userCardsIn .usersLogo {
        width: 50px;
        height: 50px;
    }

    .userCards .userCardsIn .usersBts {
        width: calc(100% - 50px)
    }

    .userCards .userCardsIn .usersPlano, .userCards .userCardsIn .usersAssAtivo{
        font-size: 14px;
    }
}

@media only screen and (min-width: 1024px) {
    .topDash {
        width: 75%;
    }
}