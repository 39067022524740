.slideUpload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
}

.slideUpload label{
    position: relative;
    height: 100%;
    cursor: pointer;
}

.slideUpload img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 2px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.slideUpload .slideChange {
    opacity: 0;
    display: flex;
    width: max-content;
    border-radius: 10px;
    gap: .2em .5em;
    font-size: 14px;
    padding: .3em;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: rgba(255,255,255,.95);
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all 350ms;
}

.slideUpload label:hover ~ .slideChange{
    opacity: 1;
}

.slideUpload input {
    display: none;
}